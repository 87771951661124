/*****************************************************************************************
* 설명 : 냉동기
 * URI : /user/management/freezer/ghpPrint1
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';

import { ghpFieldList, ghpFieldListR } from 'service/const';
import Restful from 'service/restful';

import './ghpPrint.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const GhpPrint1 = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [query, setQuery] = useState({});
  const [data, setData] = useState({});

  const [form, setForm] = useState(ghpFieldList);

  /***************************************************************************************
   * 설명 : GHP 엔진 및 주변기기 검사표 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let seq = 0;

    if( props.frzApplicationModelSeq === undefined ) seq = query.frzApplicationModelSeq;
    else seq = props.frzApplicationModelSeq;

    let params = {
      program: 'user',
      service: 'result',
      action: 'getFrzGhpInspectionInfo',
      version: '1.0',
      seq: seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let tmp = response.data.data[0];

        if( tmp ) {
          if( (tmp.inpJudgeArray ?? '') === '') {
            let judge = ghpFieldList.map((item) => item.judge);
            let memo = ghpFieldList.map((item) => item.memo);
            if (tmp.inspectionType === 'R') {
              judge = ghpFieldListR.map((item) => item.judge);
              memo = ghpFieldListR.map((item) => item.memo);
            }
            tmp.judge = judge;
            tmp.memo = memo;
          } else {
            let judge = tmp.inpJudgeArray?.split('^@^');
            let memo = tmp.inpMemoArray?.split('^@^');

            tmp.judge = judge;
            tmp.memo = memo;
          }

          setData(tmp);
          if(tmp.inspectionType === 'R')
            setForm(ghpFieldListR);
          else
            setForm(ghpFieldList);
        } else {
          setData({})
        }

      } else {
        setData({});
      }

    });
  }

  /***************************************************************************************
   * 설명 : 자동 프린트
  ***************************************************************************************/
  useEffect(() => {
    if( data.seq !== undefined && parseInt(query?.isGhpPrint1) === 1 ) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }

    // eslint-disable-next-line
  }, [data]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( query?.frzApplicationModelSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [query]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( props?.frzApplicationModelSeq !== undefined )
      getList();

    // eslint-disable-next-line
  }, [props.frzApplicationModelSeq]);

  /***************************************************************************************
   * 설명 : 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(query);

    // eslint-disable-next-line
  }, [window.location.search]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  if(data?.seq !== undefined) {
    return (
      <>
        <section className="btn-area">
          <Button
            variant="contained"
            color="primary"
            className="Btn"
            onClick={() => window.print()}
          >출력</Button>
        </section>

        <div className="print-page-top"></div>

        <section className="ghp-print">
          <div className="print-header">
            <div className="title">GHP엔진 및 주변기기 검사표</div>
            <div className="title1">
              {data?.inspectionType === 'R' &&
                '(배출가스저감장치 부착 수리)'
              }
            </div>
            <div className="date clearfix">
              <div className="fl">
                <span className="bold">접수번호 :</span>
                {data?.receptionNumber?.substr(0, 4)}
                -
                {data?.receptionNumber?.substr(4, 6)}
                -{parseInt(data?.ApplicationModelSeq)}
              </div>
              <div className="fr">
                <span className="bold mr5">검사일자 :</span>
                {
                  data?.inspectionDate !== '' && data?.inspectionDate !== undefined &&
                  moment(data?.inspectionDate).format('YYYY년 MM월 DD일')
                }
              </div>
            </div>
          </div>

          <table className="report-tbl">
            <colgroup>
              <col width="80px" />
              <col />
              <col width="80px" />
              <col />
              <col width="80px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">접 수 일 자</th>
                <td>{data?.receptionDate}</td>
                <th scope="row">냉매</th>
                <td>{data?.frzGasName}</td>
                <th scope="row">사용연료</th>
                <td>{data?.useGas}</td>
              </tr>
              <tr>
                <th scope="row">엔진제조회사</th>
                <td>{data?.engineCompanyName}</td>
                <th scope="row">수입회사</th>
                <td>{(data?.importCompanyName || '해당없음') === '해당없음' ? '' : data?.importCompanyName}</td>
                <th scope="row">냉동능력</th>
                <td>
                  {data?.frzCapacity}
                  {data?.frzCapacity && ' RT'}
                </td>
              </tr>
              <tr>
                <th scope="row">GHP 모 델 명</th>
                <td>{data?.modelName}</td>
                <th scope="row">정밀검사번호</th>
                <td>{data?.inpNum}</td>
                <th scope="row">정격엔진출력</th>
                <td>{data?.engineKw}</td>
              </tr>
              <tr>
                <th scope="row">GHP 제조번호</th>
                <td>유첨참조</td>
                <th scope="row">필증번호</th>
                <td>유첨참조</td>
                <th scope="row">엔진제조번호</th>
                <td>유첨참조</td>
              </tr>
              <tr>
                <th scope="row">검 사 업 체	</th>
                <td>{data?.inspectionCompanyName}</td>
                <th scope="row">설치장소</th>
                <td colSpan={3}>{data?.inspectionAddr}</td>
              </tr>
              <tr>
                <th scope="row">수 량	</th>
                <td>{data?.qty} 대</td>
                <th scope="row">인증성적서번호</th>
                <td>{data?.reportNumber}</td>
                <th scope="row">수리기업명</th>
                <td>{data?.repairCompanyName}</td>
              </tr>
            </tbody>
          </table>

          <table className="report-tbl">
            <colgroup>
              <col style={{width: "30px"  }}/>
              <col style={{width: "150px" }} />
              <col style={{width: "160px" }} />
              <col style={{width: "170px" }} />
              <col style={{width: "50px"  }}/>
              <col />
            </colgroup>

            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">검사항목</th>
                <th scope="col">검사기준</th>
                <th scope="col">확인내용</th>
                <th scope="col">판정</th>
                <th scope="col">비고</th>
              </tr>
            </thead>

            <tbody>
              {
                form.map((item, index) => {
                  return (
                    <tr key={item.seq}>
                      {
                        item.rowSpan > 0 &&
                        <td className="center" rowSpan={item.rowSpan}>{item.index}</td>
                      }
                      {
                        item.rowSpan === 0 &&
                        <td className="center">{item.index}</td>
                      }
                      <td className="bold">{item.label}</td>
                      <td>{item.basis}</td>
                      <td>{item.result}</td>
                      <td className="center">
                        {data?.judge !== undefined && data?.judge[index] === '001' && '적합'}
                        {data?.judge !== undefined && data?.judge[index] === '002' && '부적합'}
                        {data?.judge !== undefined && data?.judge[index] === '003' && data?.inspectionType !== 'R' && '해당없음'}
                        {data?.judge !== undefined && data?.judge[index] === '003' && data?.inspectionType === 'R' && '변경없음'}
                      </td>
                      <td>
                        {data?.memo !== undefined && data?.memo[index]}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>

          <section className="print-footer">
            <div className="footer1">
              <div className="clearfix">
                <div className="user"><span>검사원 : </span> <span style={{minWidth:'80px'}}>{data?.inspectorName}</span> (인)</div>
                <div className="sign">
                  <div className="th">기술책임자</div>
                </div>
              </div>

              <div className="clearfix">
                <div className="com">한국냉동공조안전관리원</div>
                <div className="document-no">[QI-10-13]</div>
              </div>
            </div>

            {/*props.pageNoBase &&
              <div className="clearfix">
                <div className="center f12">{props.pageNoBase ? props.pageNoBase : 0} / {props.totalPage}</div>
              </div>
            */}
          </section>
        </section>
      </>
    );
  } else {
    <></>
  }
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default GhpPrint1;